<template>
  <div>
    <GenericForm
      title="Reposição de materiais"
      previousRoute="controleEstoque"
      :model="model"
      route="reposicao"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_item"
      :permissionsToWrite="['rw_material']"
      :permissionsToDelete="['rd_material']"
      :loadOnSave="true"
      :hasBanner="true"
    >
      <template #header><div></div></template>
      <template v-slot="{ validateState, isBusy }">
        <div>
          <b-row align-v="center">
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Setor de reposição*" label-for="setorReposicao">
                <GenericSelect
                  name="setorReposicao"
                  labelKey="nome"
                  v-model="model.id_setor"
                  route="setor"
                ></GenericSelect>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Motivo*" label-for="motivo">
                <b-form-input
                  id="motivo"
                  v-model="model.motivo"
                  autocomplete="off"
                  class="invision-input"
                  name="motivo"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('motivo')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Leitura do Instrumento*" label-for="code">
                <b-form-input
                  id="code"
                  v-model="model.code"
                  autocomplete="off"
                  class="invision-input"
                  name="code"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('code')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import GenericApi from '@/services/genericRequest';
import GenericForm from '@/components/Form/GenericForm';
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  components: {
    GenericForm,
    GenericSelect,
  },
  provide() {
    return { parentValidator: this.$parent.$validator };
  },
  props: {
    tipoMaterial: {
      type: String,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
  },

  async mounted() {
    this.model.id_material = this.$route.params.id;
    this.model.material = await this.getMaterial();
    this.model.id_setor = this.model.material.id_setor;
  },

  data() {
    return {
      tab: 'material',
      model: {
        motivo: '',
        id_setor: null,
        code: '',
        material: {},
      },
    };
  },

  methods: {
    async getMaterial() {
      return this.api.getOne(this.model.id_material, 'material');
    },

    async updateModel(model) {
      this.model = model;
      await this.$emit('modelUpdated', model);
    },
  },
};
</script>

<style>
.box-change-material-type {
  font-size: 15px;
  margin-bottom: 10px;
  color: #21cca9;
}
.tooltip-icon {
  width: 16px;
  margin-left: 0.5rem;
  vertical-align: text-bottom;
  color: #35384d;
}
.material-nav {
  list-style: none;
  padding-inline-start: 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #CFD1E6;
  margin-bottom: 36px;
}
.material-nav li{
  color: #7E829F;;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 24px 14px 24px;
}
.nav-item-active {
  border-bottom: 3px solid #209F85 !important;
  color: #209F85 !important;
  margin-bottom: -2.5px;
}
.info-yes-no {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #35384D;
}
</style>
